export  const BackOfficeMenu = [
    {
        name: ' Bad Debt Recovery ',
        url: '/baddebtrecovery'
    },
    {
        name: 'Data Entry & Content Management  ',
        url: '/dataentrycontentmanagement'
    },
    {
        name: 'Digital Support Services ',
        url: '/digitalsupportservices'
    }
]