import React from 'react'

function PrimaryBtn({children}) {
  return (
    <>
    <a href='#' className="Btn BtnOrange">{children}</a>
    </>
  )
}

export default PrimaryBtn