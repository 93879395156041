export  const SoftwareDevelopMenu = [
    {
        name: 'Custom Software Development',
        url: '/customsoftwaredevelopment'
    },
    {
        name: 'Mobile Application Development',
        url: '/mobileAppdevelopment'
    },
    {
        name: 'Software Consulting',
        url: '/softwareconsulting'
    },
    {
        name: 'BOP Solutions',
        url: '/bposolutions'
    },
    {
        name: 'Business Software',
        url: '/businesssoftware'
    }

    
]