export  const DigitalServicesMenu = [
    {
        name: ' SMS Business ',
        url: '/smsBusiness'
    },
    {
        name: 'Platform Management',
        url: '/platformManagement'
    },
    {
        name: 'Short Code support',
        url: '/shortCodesupport'
    }
]