

const HeadItem = [
    {
        title: 'About',
        url: '/about'
    },
    {
        title: ' Career',
        url: '/career'
    },
    {
        title: ' How We Work',
        url: '/work'
    },
    {
        title: ' Blog',
        url: '/blog'
    },
    {
        title: ' Newsroom',
        url: '/newsroom'
    },
    {
        title: ' Contact',
        url: '/contact'
    }
    
]

export default HeadItem