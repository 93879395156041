export  const TrainingHRsourcingMenu = [
    {
        name: 'Training ',
        url: '/training'
    },
    {
        name: 'Skill Developmentprogram',
        url: '/skilldevelopmentprogram'
    },
    {
        name: 'Hrsourceing',
        url: '/hrsourceing'
    }
]