export  const CallCenterServicesMenu = [
    {
        name: 'Inboundservices ',
        url: '/inboundservices'
    },
    {
        name: 'Outboundservices',
        url: '/outboundservices'
    },
    {
        name: 'Specializedservices',
        url: '/specializedservices'
    }
]